import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div>
      <body>
        <Navbar />

        <section class="home" id="home">
          <div class="image">
            <img src="image/home-img.svg" alt="" />
          </div>

          <div class="content">
            <h3>RCTECHBOX IT SERVICES</h3>
            <p>
              We think strategy, craft design, develop digital and create
              motion. To forward your brand and business.
            </p>
            <a href="#" class="btn">
              read more
            </a>
          </div>
        </section>

        <section class="services" id="services">
          <h1 class="heading">
            {" "}
            our <span>services</span>{" "}
          </h1>

          <div class="box-container">
            <div class="box">
              <img src="image/s-1.svg" alt="" />
              <h3>layout design</h3>
              <p>
                Layout design is important for any project that conveys a
                message through eye-catching visuals, like magazine layouts,
                website design, and advertisements
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>

            <div class="box">
              <img src="image/s-2.svg" alt="" />
              <h3>responsive design</h3>
              <p>
                The goal of responsive design is to build web pages that detect
                the visitor's screen size and orientation and change the layout
                accordingly.
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>

            <div class="box">
              <img src="image/s-3.svg" alt="" />
              <h3>digital marketing</h3>
              <p>
                Digital marketing include social media, email, pay-per-click
                (PPC), search engine optimization (SEO), and more.
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>

            <div class="box">
              <img src="image/s-4.svg" alt="" />
              <h3>seo marketing</h3>
              <p>
                SEO in marketing your business is the process of improving your
                website so that search engines like Google are more likely to
                show your business in users' search results.
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>

            <div class="box">
              <img src="image/s-5.svg" alt="" />
              <h3>database</h3>
              <p>
                database is information that is set up for easy access,
                management and updating. Computer databases typically store
                aggregations of data records or files that contain information,
                such as sales transactions, customer data, financials and
                product information.
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>

            <div class="box">
              <img src="image/s-6.svg" alt="" />
              <h3>optimized coding</h3>
              <p>
                A program may be optimized so that it becomes a smaller size,
                consumes less memory, executes more rapidly, or performs fewer
                input/output operations.
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>
          </div>
        </section>

        <section class="about" id="about">
          <h1 class="heading">
            {" "}
            <span>about</span> us{" "}
          </h1>

          <div class="row">
            <div class="image">
              <img src="image/about-img.svg" alt="" />
            </div>
            <div class="content">
              <h3>We offer the tools and skills that your company deserves</h3>
              <p>
                Increase visibility to increase profits. We help our clients
                increase profits by increasing their visibility online.
              </p>
              <p>
                <ul>
                  <li>Ongoing maintenance to your website and server</li>
                  <li>We have produced hundreds of website designs</li>
                  <li>Strategic consulting in online marketing</li>
                  <li>We work with all the major payment processors</li>
                </ul>
              </p>
              <a href="#" class="btn">
                read more
              </a>
            </div>
          </div>
        </section>

        <section class="plan" id="plan">
          <h1 class="heading">
            {" "}
            our <span>plan</span>{" "}
          </h1>

          <div class="box-container">
            <div class="box">
              <h3 class="title">basic plan</h3>
              <img src="image/price-img-1.svg" alt="" />
              <h3 class="price">
                {" "}
                <span>$</span>30<span>/mo</span>{" "}
              </h3>
              <ul>
                <li>seo marketing</li>
                <li>web hosting</li>
                <li>database</li>
                <li>unlimited storage</li>
                <li>maintainance</li>
              </ul>
              <a href="#" class="btn">
                choose plan
              </a>
            </div>

            <div class="box">
              <h3 class="title">standard plan</h3>
              <img src="image/price-img-2.svg" alt="" />
              <h3 class="price">
                {" "}
                <span>$</span>60<span>/mo</span>{" "}
              </h3>
              <ul>
                <li>seo marketing</li>
                <li>web hosting</li>
                <li>database</li>
                <li>unlimited storage</li>
                <li>maintainance</li>
              </ul>
              <a href="#" class="btn">
                choose plan
              </a>
            </div>

            <div class="box">
              <h3 class="title">premium plan</h3>
              <img src="image/price-img-3.svg" alt="" />
              <h3 class="price">
                {" "}
                <span>$</span>90<span>/mo</span>{" "}
              </h3>
              <ul>
                <li>seo marketing</li>
                <li>web hosting</li>
                <li>database</li>
                <li>unlimited storage</li>
                <li>maintainance</li>
              </ul>
              <a href="#" class="btn">
                choose plan
              </a>
            </div>
          </div>
        </section>

        <section class="review" id="review">
          <h1 class="heading">
            {" "}
            client's <span>review</span>{" "}
          </h1>

          <div class="box-container">
            <div class="box">
              <img src="image/pic-1.png" alt="" />
              <h3>Rocky</h3>
              <p>They Have good Team,and very Supportive.</p>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
              </div>
            </div>

            <div class="box">
              <img src="image/pic-1.png" alt="" />
              <h3>Jyothsna CA</h3>
              <p>
                They Supported for My business creating website , and also help
                to get the leads to my firm.
              </p>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
              </div>
            </div>

            <div class="box">
              <img src="image/pic-1.png" alt="" />
              <h3>Sampath Kumar</h3>
              <p>Very Supportve, On Time Delivery.</p>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
              </div>
            </div>

            <div class="box">
              <img src="image/pic-1.png" alt="" />
              <h3>Abhishek</h3>
              <p>I Impressed with their work style,and very supportive.</p>
              <div class="stars">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
              </div>
            </div>
          </div>
        </section>

        <section class="contact" id="contact">
          <h1 class="heading">
            {" "}
            <span>contact</span> us{" "}
          </h1>

          <div class="row">
            <div class="image">
              <img src="image/contact-img.svg" alt="" />
            </div>
            <form action="">
              <h3>get in touch</h3>
              <input type="text" placeholder="full name" class="box" />
              <input type="email" placeholder="your email" class="box" />
              <input type="number" placeholder="your number" class="box" />
              <textarea
                name=""
                placeholder="your message"
                class="box"
                id=""
                cols="30"
                rows="10"
              ></textarea>
              <input type="submit" value="send message" class="btn" />
            </form>
          </div>
        </section>

        <section class="footer">
          <div class="share">
            <a href="#" class="fab fa-facebook-f"></a>
            <a href="#" class="fab fa-linkedin"></a>
            <a href="#" class="fab fa-twitter"></a>
            <a href="#" class="fab fa-instagram"></a>
            <a href="#" class="fab fa-pinterest"></a>
          </div>

          <div class="credit">
            {" "}
            created by <span>RCTECHBOX IT SERVICES</span> | all rights reserved{" "}
          </div>
        </section>
      </body>
    </div>
  );
}

export default App;
